import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogHeader,
  DialogContent,
  DialogTrigger,
} from './ui/dialog'
import { Button } from './ui/button'
import { ArchiveInput } from './ArchiveInput'
import { PlusIcon } from 'lucide-react'
import { Link } from 'react-router-dom'
import { AppRoutes } from '@/routes'
import { useState } from 'react'

interface Props {
  buttonLabel?: string
}

export function NewArchiveModal(props: Props) {
  const { buttonLabel } = props

  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="theme" size={buttonLabel ? 'default' : 'icon'}>
          <PlusIcon className="h-4 w-4" />
          {buttonLabel && buttonLabel}
        </Button>
      </DialogTrigger>
      <DialogContent aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>New Archive</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <ArchiveInput onSuccess={() => setOpen(false)} />

          <Link
            to={AppRoutes.NEW_ARCHIVE}
            onClick={() => setOpen(false)}
            className="z-10 mx-auto inline-flex items-center gap-1 border-b border-transparent p-0 text-sm"
          >
            or submit your own text
            <PlusIcon className="h-4 w-4" />
          </Link>
        </div>
      </DialogContent>
    </Dialog>
  )
}
