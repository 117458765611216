import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'

import { cn } from '@/lib/utils'
import { cva, type VariantProps } from 'class-variance-authority'

const sliderVariants = cva('', {
  variants: {
    variant: { default: 'bg-primary', theme: 'bg-theme' },
  },
  defaultVariants: { variant: 'default' },
})

interface Props extends VariantProps<typeof sliderVariants> {}

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & Props
>(({ className, variant, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex w-full touch-none select-none items-center',
      className,
    )}
    {...props}
  >
    <SliderPrimitive.Track className="bg-secondary relative h-2 w-full grow overflow-hidden rounded-full">
      <SliderPrimitive.Range
        className={cn('absolute h-full', sliderVariants({ variant }))}
      />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="border-border bg-background ring-offset-background focus-visible:ring-ring block h-5 w-5 rounded-full border transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
  </SliderPrimitive.Root>
))
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
