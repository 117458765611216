import { ArchiveCard } from '@/components/ArchiveCard'
import { ArchiveInput } from '@/components/ArchiveInput'
import { PriceToggle } from '@/components/PriceToggle'
import { api } from '@/lib/api'
import { formatFileSize, formatNumber } from '@/lib/formatting'
import { AppRoutes } from '@/routes'
import { ClockIcon, DatabaseIcon, PlusIcon, ShieldIcon } from 'lucide-react'
import { useRef } from 'react'
import { Link } from 'react-router-dom'

export function HomePage() {
  const { data: archives } = api.archives.browse.useQuery({
    limit: 3,
    featured: true,
    status: 'all',
  })

  const { data: btcInfo } = api.ordinals.btcFees.useQuery()
  const { data: stats } = api.archives.stats.useQuery()

  const browseRef = useRef<HTMLElement>(null)

  return (
    <div className="flex flex-col">
      <section className="bg-background text-foreground flex w-full flex-col items-center px-4 pt-36 sm:px-6">
        <div className="flex w-full flex-1 flex-col items-center justify-center text-center">
          <h1 className="lg:leading-tighter font-serif text-3xl sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
            Archive the Internet on Bitcoin
          </h1>
          <p className="text-muted-foreground mt-2 max-w-2xl text-lg">
            The permanent internet archive. Save today, access forever.
          </p>
          <div className="mt-6 flex w-full max-w-screen-sm flex-col gap-1.5">
            <ArchiveInput autoFocus />
            <Link
              to={AppRoutes.NEW_ARCHIVE}
              className="hover:border-foreground text-muted-foreground z-10 mx-auto inline-flex items-center gap-1 border-b border-transparent p-0 text-sm"
            >
              or submit your own text
              <PlusIcon className="h-4 w-4" />
            </Link>
          </div>
        </div>
      </section>
      <section className="mt-32 pb-12 pt-12 lg:pb-12" ref={browseRef}>
        <div className="container">
          <div className="mx-auto grid max-w-7xl grid-cols-1 gap-6 md:grid-cols-3">
            {archives?.archives.map((archive) => (
              <ArchiveCard
                key={archive._id}
                archive={archive}
                btcPriceUsd={btcInfo?.price ?? 0}
              />
            ))}
          </div>
          <div className="mt-2 text-center">
            <Link
              to={AppRoutes.FEED}
              className="text-muted-foreground hover:text-primary text-sm hover:underline"
            >
              View all archives &rarr;
            </Link>
          </div>
        </div>
        {/* <FeedPage
          pageSize={8}
          disableEndlessScroll
          hideFilters
          disableViewUrlChanges
          hideViewToggle={showOverlay}
        /> */}
        {/* <div className="mt-4 flex justify-center">
          <Link
            to={AppRoutes.FEED}
            className={`${buttonVariants({ variant: 'ghost' })} flex items-center gap-1.5`}
          >
            View all <ArrowRightIcon className="h-5 w-5" />
          </Link>
        </div> */}
      </section>
      {/* <div
        className={`pointer-events-none fixed inset-0 z-10 hidden h-[100dvh] min-h-screen flex-col justify-end transition-opacity duration-500 sm:flex ${cn(
          {
            'opacity-0': !showOverlay,
          },
        )}`}
      >
        <div
          className={`to-background via-background/70 pointer-events-none h-[15vh] w-full bg-gradient-to-b from-transparent transition-opacity duration-500 sm:h-[25vh]`}
        />
      </div> */}
      {/* <div
        className={`fixed inset-x-0 bottom-0 z-20 hidden justify-center pb-1.5 transition-opacity duration-500 sm:flex ${cn({ 'pointer-events-none opacity-0': !showOverlay })}`}
      >
        <button onClick={scrollToBrowse} className={`text-primary`}>
          <ChevronsDownIcon className="stroke-primary h-6 w-6" />
        </button>
      </div> */}
      <section className="bg-muted/50 border-t">
        <div className="container py-12 lg:py-28">
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="flex flex-col items-center space-y-4 text-center">
              <ShieldIcon className="text-primary h-8 w-8" />
              <h3 className="text-xl font-semibold">Immutable Storage</h3>
              <p className="text-muted-foreground">
                Your content is permanently stored on the Bitcoin blockchain,
                ensuring it can never be altered or deleted.
              </p>
            </div>
            <div className="flex flex-col items-center space-y-4 text-center">
              <ClockIcon className="text-primary h-8 w-8" />
              <h3 className="text-xl font-semibold">Easy Archiving</h3>
              <p className="text-muted-foreground">
                Archive any webpage or content instantly with just a URL, or
                submit your own text.
              </p>
            </div>
            <div className="flex flex-col items-center space-y-4 text-center">
              <DatabaseIcon className="text-primary h-8 w-8" />
              <h3 className="text-xl font-semibold">Decentralized Access</h3>
              <p className="text-muted-foreground">
                Access your archived content from anywhere, anytime, without
                relying on centralized servers.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="border-t">
        <div className="px-4 pb-4 pt-12 md:container lg:pb-16 lg:pt-28">
          <div className="mx-auto max-w-5xl text-center">
            {/* <h2 className="text-3xl font-bold sm:text-4xl">Statistics</h2> */}
            <div className="mt-8 grid gap-4 md:grid-cols-3">
              <div className="bg-card rounded-lg border p-6">
                <div className="text-4xl font-bold">
                  {formatNumber(stats?.totalArchives)}
                </div>
                <p className="text-muted-foreground text-sm">
                  Archives Created
                </p>
              </div>
              <div className="bg-card rounded-lg border p-6">
                <div className="text-4xl font-bold">
                  {formatFileSize(stats?.totalOnChainSize ?? 0)}
                </div>
                <p className="text-muted-foreground text-sm">On-Chain Data</p>
              </div>
              <div className="bg-card rounded-lg border p-6">
                <div className="text-4xl font-bold">
                  <PriceToggle
                    sats={stats?.totalFeesSats ?? 0}
                    bitcoinPrice={btcInfo?.price ?? 0}
                    className="cursor-pointer"
                  />
                </div>
                <p className="text-muted-foreground text-sm">Miner Fees Paid</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
