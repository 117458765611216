import { MarkdownView } from '@/components/MarkdownView'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Textarea } from '@/components/ui/textarea'
import { toast } from '@/components/ui/use-toast'
import { useDebouncedInput } from '@/hooks/useDebouncedInput'
import { api } from '@/lib/api'
import { formatCurrency, formatFileSize } from '@/lib/formatting'
import { cn } from '@/lib/utils'
import { AppRoutes } from '@/routes'
import { IArchive } from '@/types'
import { MAX_RAW_CONTENT_SIZE } from 'backend/src/lib/constants'
import { LoaderCircleIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

async function sha256(message: string): Promise<string> {
  // Encode the message as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // Hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

  // Convert ArrayBuffer to byte array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // Convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')

  return hashHex
}

type Format = NonNullable<IArchive['format']>

export function NewArchivePage() {
  const [hash, setHash] = useState('')
  const [format, setFormat] = useState<Format>('plain-text')

  const navigate = useNavigate()

  const {
    inputValue: title,
    debouncedValue: debouncedTitle,
    handleInputChange: handleTitleInputChange,
  } = useDebouncedInput({ delay: 500, initialValue: '' })

  const { inputValue: author, handleInputChange: handleAuthorInputChange } =
    useDebouncedInput({ delay: 500, initialValue: '' })

  const {
    inputValue: content,
    debouncedValue: debouncedContent,
    handleInputChange: handleContentInputChange,
  } = useDebouncedInput({ delay: 500, initialValue: '' })

  const debouncedSize = new Blob([debouncedContent]).size

  const { data: fees, isFetching: loadingFees } =
    api.ordinals.estimateFeesRawData.useQuery(
      {
        title: debouncedTitle,
        size: debouncedSize,
      },
      { enabled: !!debouncedContent },
    )

  const createRawMutation = api.archives.createRaw.useMutation({
    onSuccess: ({ url }) => {
      navigate(AppRoutes.buildArchiveRoute(url))
      toast({
        title: 'Archive Created',
        description: 'Your archive has been successfully created.',
      })
    },
    onError: (error) => {
      toast({
        title: 'Error Creating Archive',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  const rawContentTooLarge = new Blob([content]).size > MAX_RAW_CONTENT_SIZE

  useEffect(() => {
    const hashContent = async () => {
      if (!content) {
        setHash('')
        return
      }

      setHash(await sha256(content))
    }

    hashContent()
  }, [content])
  return (
    <div
      className={`mx-auto mt-16 w-full flex-1 ${cn({
        'max-w-screen-xl': format === 'markdown',
        'max-w-screen-md': format !== 'markdown',
      })}`}
    >
      <h1 className="text-2xl font-bold">Create New Archive</h1>
      <div className="mt-8 flex w-full flex-1 flex-col items-center gap-4 self-stretch md:flex-row">
        <form
          className="w-full"
          onSubmit={(e) => {
            e.preventDefault()

            if (rawContentTooLarge) {
              toast({
                title: 'Content Too Large',
                description: `Content size exceeds limit of ${formatFileSize(MAX_RAW_CONTENT_SIZE)}.`,
                variant: 'destructive',
              })
              return
            }

            if (!title || !content) {
              toast({
                title: 'Missing Data',
                description: 'Title and content are required.',
                variant: 'destructive',
              })
              return
            }

            createRawMutation.mutate({
              title,
              author,
              content,
              format,
            })
          }}
        >
          <div className="grid gap-4">
            <Input
              placeholder="Title"
              value={title}
              onChange={handleTitleInputChange}
            />
            <Input
              placeholder="Author (optional)"
              value={author}
              onChange={handleAuthorInputChange}
            />
            <Textarea
              placeholder="Content"
              value={content}
              rows={20}
              onChange={handleContentInputChange}
              className="whitespace-pre-wrap"
              style={{
                fontFamily: format === 'ascii-art' ? 'monospace' : undefined,
              }}
            />
            <div className="flex justify-end">
              <Select
                value={format}
                onValueChange={(value) => setFormat(value as Format)}
              >
                <SelectTrigger className="max-w-32">
                  <SelectValue placeholder="Format" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="plain-text">Plain Text</SelectItem>
                  <SelectItem value="ascii-art">Ascii Art</SelectItem>
                  <SelectItem value="markdown">Markdown</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <p
              className={cn(
                'text-muted-foreground text-sm',
                rawContentTooLarge && 'text-destructive',
              )}
            >
              {formatFileSize(new Blob([content]).size)} /{' '}
              {formatFileSize(MAX_RAW_CONTENT_SIZE)}
            </p>
            <p className={'text-muted-foreground flex items-center text-sm'}>
              Estimated cost:{' '}
              {!content ? (
                formatCurrency(0)
              ) : loadingFees ? (
                <LoaderCircleIcon className="ml-2 h-4 w-4 animate-spin" />
              ) : (
                formatCurrency(fees?.premiumFeesUsd ?? 0)
              )}
            </p>
            <p className={'text-muted-foreground break-all text-sm'}>
              Digital fingerprint: {hash}
            </p>
          </div>
          <Button
            type="submit"
            className="mt-4 w-full"
            disabled={
              createRawMutation.isPending ||
              rawContentTooLarge ||
              !title ||
              !content
            }
          >
            {createRawMutation.isPending ? 'Creating...' : 'Create'}
            {createRawMutation.isPending && (
              <LoaderCircleIcon className="ml-2 h-4 w-4 animate-spin" />
            )}
          </Button>
        </form>
        {format === 'markdown' && (
          <div className="flex w-full flex-col self-stretch [word-break:break-all]">
            <div className="text-muted-foreground mb-2 text-sm">Preview:</div>
            <MarkdownView
              className="sm:prose-base prose-sm border-border flex-1 rounded-md border p-4"
              content={content}
            />
          </div>
        )}
      </div>
    </div>
  )
}
