import { AppRoutes } from '@/routes'
import { Link } from 'react-router-dom'
import { Card, CardHeader, CardContent, CardTitle, CardFooter } from './ui/card'
import { IArchive } from '@/types'
import { Badge } from './ui/badge'
import { formatDateLong, formatDateWithTime } from '@/lib/formatting'
import { cn, getBlockUrl, getOrdinalExplorerUrl } from '@/lib/utils'
import { PriceToggle } from './PriceToggle'
import { useState } from 'react'

interface Props {
  archive: IArchive
  feeRank?: number
  btcPriceUsd: number
}

export function ArchiveCard(props: Props) {
  const { archive, feeRank, btcPriceUsd } = props

  const ordinalId = archive.fullTextTx?.ordinalId || archive.hashTx?.ordinalId
  const txid = archive.fullTextTx?.txid || archive.hashTx?.txid
  const blockHeight =
    archive.fullTextTx?.blockHeight || archive.hashTx?.blockHeight
  const satsPerByte =
    archive.fullTextTx?.satsPerByte || archive.hashTx?.satsPerByte
  const totalSats = archive.fullTextTx?.totalSats || archive.hashTx?.totalSats

  const [linkUrl, setLinkUrl] = useState(
    AppRoutes.buildArchiveRoute(archive.url),
  )

  return (
    <div
      className={`relative flex w-full flex-1 flex-col items-center gap-2 pt-3 sm:flex-row`}
    >
      {!!feeRank && !!ordinalId && (
        <div
          className={`mb-1 flex flex-col items-center justify-center break-words font-mono text-xs leading-normal sm:mb-0`}
        >
          <div className="flex flex-row-reverse items-center justify-center gap-3 text-center sm:flex-col">
            <div className="text-muted-foreground text-center">#{feeRank}</div>
          </div>
        </div>
      )}
      <div className="relative w-full min-w-0 flex-1">
        <div className="absolute inset-x-0 -top-3 flex items-center justify-between gap-2 px-4 sm:-top-3 sm:justify-end">
          {!ordinalId && <Badge variant="secondary">Off-chain</Badge>}
          {satsPerByte && (
            <>
              <Badge variant="secondary" className="select-none">
                <PriceToggle
                  sats={totalSats ?? 0}
                  bitcoinPrice={btcPriceUsd}
                  className="cursor-pointer"
                />
              </Badge>
              <Badge variant="default" className="select-none">
                {satsPerByte} sats/byte
              </Badge>
            </>
          )}
        </div>
        <Link
          to={linkUrl}
          target={linkUrl.startsWith('http') ? '_blank' : undefined}
          rel="noreferrer"
          key={archive._id}
          className="block w-full min-w-0 transition duration-300 ease-in-out data-[closed]:-translate-x-full data-[closed]:opacity-0"
        >
          <Card className="flex h-full min-h-36 w-full min-w-0 flex-col justify-between">
            <CardHeader className={`p-4 pb-2 ${cn({ 'pt-6': !!txid })}`}>
              <CardTitle className="line-clamp-3 text-lg leading-tight underline-offset-4 group-hover:underline sm:line-clamp-none sm:truncate">
                {archive.title || new URL(archive.url).hostname}
              </CardTitle>
            </CardHeader>
            <CardContent className="p-4 pb-2 pt-0 text-sm">
              <p className="truncate">
                {archive.excerpt ?? archive.textContent}
              </p>
            </CardContent>
            <CardFooter className="text-muted-foreground flex items-center gap-2 p-4 pt-2 font-mono text-xs">
              <time
                title={formatDateWithTime(archive.date)}
                className="text-muted-foreground"
              >
                {formatDateLong(archive.date)}
              </time>

              {blockHeight ? (
                <>
                  <div>|</div>
                  <span
                    className="hover:underline"
                    onMouseOver={() => setLinkUrl(getBlockUrl(blockHeight))}
                    onMouseOut={() =>
                      setLinkUrl(AppRoutes.buildArchiveRoute(archive.url))
                    }
                  >
                    Block #{blockHeight}
                  </span>
                </>
              ) : null}
            </CardFooter>
          </Card>
        </Link>
      </div>
      <div
        className={`mb-4 w-full max-w-full break-words text-center font-mono text-xs leading-normal sm:mb-0 sm:max-w-16 sm:text-left ${ordinalId || archive.mintingInProgress ? 'text-theme' : 'text-muted-foreground'}`}
      >
        {ordinalId && txid ? (
          <a
            href={getOrdinalExplorerUrl(ordinalId)}
            target="_blank"
            rel="noopener noreferrer"
            className="block truncate underline-offset-4 hover:underline sm:whitespace-normal"
          >
            {txid}
          </a>
        ) : (
          <div
            className={`truncate sm:whitespace-normal ${cn({ 'opacity-50': !archive.mintingInProgress, 'animate-pulse': archive.mintingInProgress })}`}
          >
            {new Array(64).fill(0).join('')}
          </div>
        )}
      </div>
    </div>
  )
}
