import { useAnalytics } from '@/hooks/useAnalytics'
import { api } from '@/lib/api'
import { AppRoutes } from '@/routes'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from './ui/use-toast'
import { Input } from './ui/input'
import { Button } from './ui/button'
import { LoaderCircleIcon, SquarePenIcon } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip'

interface Props {
  autoFocus?: boolean
  small?: boolean
  onSuccess?: (url: string) => void
}

export function ArchiveInput(props: Props) {
  const { autoFocus, onSuccess, small } = props

  const [url, setUrl] = useState('')

  const analytics = useAnalytics()
  const navigate = useNavigate()

  const mutation = api.archives.scrape.useMutation({
    onSuccess: async (url) => {
      await new Promise((resolve) => setTimeout(resolve, 2000))

      navigate(AppRoutes.buildArchiveRoute(url))

      setUrl('')
      onSuccess?.(url)
    },
    onError: (error) => {
      toast({
        title: 'Error Archiving',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  return (
    <form
      className={`flex flex-1 items-center justify-between overflow-hidden rounded-md border focus-within:border-indigo-500`}
      onSubmit={(e) => {
        analytics.sendEvent('page_scraped', { sraped_url: url })

        e.preventDefault()

        mutation.mutate({ url })
      }}
    >
      <Input
        type="text"
        placeholder={small ? 'Enter a URL to archive' : 'Enter a URL'}
        className={`flex-1 rounded-md border-none px-3 focus-visible:rounded-r-none focus-visible:ring-0 ${cn({ 'h-auto py-2': small })}`}
        onChange={(e) => setUrl(e.target.value)}
        autoFocus={autoFocus}
        value={url}
      />
      <Button
        type="submit"
        variant="theme"
        className={`rounded-none px-5 disabled:opacity-70 ${cn({ 'h-auto py-2': small })}`}
        disabled={mutation.isPending}
      >
        {mutation.isPending ? 'Archiving...' : 'Archive'}
        {mutation.isPending && <LoaderCircleIcon className="animate-spin" />}
      </Button>
    </form>
  )
}
