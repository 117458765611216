import { MenuIcon, SquarePenIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { ArchiveInput } from './components/ArchiveInput'
import { AudioPlayer } from './components/AudioPlayer'
import { NewArchiveModal } from './components/NewArchiveModal'
import { ProfileLink } from './components/ProfileLink'
import { ThemeToggle } from './components/ThemeToggle'
import { buttonVariants } from './components/ui/button'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from './components/ui/sheet'
import { Toaster } from './components/ui/toaster'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from './components/ui/tooltip'
import { useDidScroll } from './hooks/useDidScroll'
import useUser from './hooks/useUser'
import { cn } from './lib/utils'
import { AppRoutes } from './routes'
import { getMetatags } from 'backend/src/lib/metatags'
import { Helmet } from 'react-helmet'

const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent)
const isChrome = !!window.chrome

export function Layout() {
  const [showArchiveInput, setShowArchiveInput] = useState(false)
  const { user } = useUser()

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const didScroll = useDidScroll({ threshold: 20 })

  const { pathname } = useLocation()

  const isHomePage = pathname === AppRoutes.HOME

  const [metaTitle, setMetaTitle] = useState('')

  useEffect(() => {
    setMobileMenuOpen(false)

    const metatags = getMetatags(pathname)

    if (metatags) {
      setMetaTitle(metatags.title)
    }
  }, [pathname])

  return (
    <div className="flex min-h-dvh w-full flex-col">
      {metaTitle ? (
        <Helmet title={metaTitle}>
          <meta property="og:title" content={metaTitle} />
        </Helmet>
      ) : null}
      <header className="flex flex-row items-center justify-between gap-2 px-6 py-2 md:container">
        <Link to="/" className="flex items-center space-x-2">
          <img
            src="/logo-black.png"
            alt="Ark Logo"
            width={64}
            className="w-16 dark:hidden"
          />
          <img
            src="/logo-white.png"
            alt="Ark Logo"
            width={64}
            className="hidden w-16 dark:block"
          />
        </Link>
        <div className="flex items-center gap-2 md:hidden">
          {!isHomePage && <NewArchiveModal />}

          {/* Mobile menu */}
          <Sheet open={mobileMenuOpen} onOpenChange={setMobileMenuOpen}>
            <SheetTrigger>
              <MenuIcon />
            </SheetTrigger>

            <SheetContent aria-describedby={undefined}>
              <SheetHeader>
                <SheetTitle className="sr-only">Menu</SheetTitle>
              </SheetHeader>
              <div className="mt-3 flex h-full w-full flex-col gap-2 text-sm">
                <Link to={AppRoutes.FEED} className="block">
                  Browse
                </Link>
                {user ? <Link to={AppRoutes.PROFILE}>Profile</Link> : null}
                <Link to={AppRoutes.BOOKMARKS} className="block">
                  Bookmarks
                </Link>
                {!user ? (
                  <Link
                    to={AppRoutes.LOGIN}
                    className={buttonVariants({ variant: 'outline' })}
                  >
                    Login
                  </Link>
                ) : null}
                <div className="flex flex-1 flex-col justify-end">
                  <NewArchiveModal buttonLabel="New Archive" />
                </div>
              </div>
            </SheetContent>
          </Sheet>
        </div>
        <div className="hidden w-full flex-1 items-center justify-center gap-4 md:flex md:justify-start">
          <div className="flex w-full flex-1 items-center justify-center">
            <div className={cn('flex w-full max-w-2xl flex-1 px-4')}>
              {!isHomePage && (
                <div className="flex w-full items-center gap-2">
                  <ArchiveInput small />

                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Link to={AppRoutes.NEW_ARCHIVE}>
                        <SquarePenIcon className="size-5" />
                      </Link>
                    </TooltipTrigger>
                    <TooltipContent>Submit your own text</TooltipContent>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
          <Link to={AppRoutes.FEED} className="text-sm">
            Browse
          </Link>
          <Link to={AppRoutes.BOOKMARKS} className="text-sm">
            Bookmarks
          </Link>
          <ProfileLink />
        </div>
      </header>

      {/* Mobile search */}
      <div
        className={cn('flex-1 px-4 sm:hidden', {
          flex: showArchiveInput,
          hidden: !showArchiveInput,
        })}
      >
        <ArchiveInput small />
      </div>
      <main
        className={`flex flex-1 flex-col pb-12 ${cn({
          'px-4 sm:px-6': !isHomePage,
        })}`}
      >
        <Outlet />
      </main>

      {/* Footer */}
      <footer className="p-4 md:container sm:p-6">
        <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
          <p className="text-muted-foreground text-sm">
            &copy; {new Date().getFullYear()} Ark. All rights reserved.
          </p>
          <nav className="flex items-center gap-4">
            <Link
              to={AppRoutes.PRIVACY_POLICY}
              className="text-muted-foreground text-sm hover:underline"
            >
              Privacy Policy
            </Link>
            <Link
              to={AppRoutes.STATS}
              className="text-muted-foreground text-sm hover:underline"
            >
              Stats
            </Link>
            <Link
              to={AppRoutes.ABOUT}
              className="text-muted-foreground text-sm hover:underline"
            >
              About
            </Link>
            <Link
              to={AppRoutes.DOWNLOADS}
              className="text-muted-foreground text-sm hover:underline"
            >
              Downloads
            </Link>
            <ThemeToggle />
          </nav>
        </div>
      </footer>
      <AudioPlayer />
      <Toaster />
    </div>
  )
}

// const DownloadLink = () => {
//   return (
//     <Link
//       to={AppRoutes.DOWNLOADS}
//       className={buttonVariants({
//         size: 'icon',
//         variant: 'secondary',
//         className: 'relative overflow-hidden bg-transparent',
//       })}
//     >
//       <div className="animate-gradient from-background absolute inset-0 bg-gradient-to-tr to-indigo-300 bg-[length:200%_200%]" />
//       <div className="absolute inset-0 flex items-center justify-center">
//         <DownloadIcon className="h-4 w-4" />
//       </div>
//     </Link>
//   )
// }
