export const EMAILS = {
  MEMORY: 'memory@ark.page',
} as const

export const MAX_RAW_CONTENT_SIZE = 1024 * 1024 * 4 // 4mb

/**
 * Routes are defined in the backend so that we can build server-side metatags.
 */
export const ROUTES = {
  HOME: '/',
  NEW_ARCHIVE: '/new',
  /** @deprecated use `buildArchiveRoute` instead */
  ARCHIVE: '/archive',
  /** @deprecated */
  BROWSE: '/browse',
  FEED: '/feed',
  ADMIN: '/admin/:adminSecret',
  ABOUT: '/about',
  DIGGING_DEEPER: '/digging-deeper',
  STATS: '/stats',
  PRIVACY_POLICY: '/privacy-policy',
  DOWNLOADS: '/downloads',
  LOGIN: '/login',
  SIGNUP: '/signup',
  BOOKMARKS: '/bookmarks',
  PROFILE: '/profile',
} as const
