import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import { toast } from '@/components/ui/use-toast'
import useUser from '@/hooks/useUser'
import { api } from '@/lib/api'
import { IComment, ICommentVote } from '@/types'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LoaderCircleIcon,
  MessageSquareIcon,
  MoreVerticalIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  Trash2Icon,
} from 'lucide-react'
import { useState } from 'react'
import { formatDistanceToNowStrict } from 'date-fns'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { useAnalytics } from '@/hooks/useAnalytics'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { formatDateWithTime, formatNumber } from '@/lib/formatting'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { InView } from 'react-intersection-observer'
import { cn } from '@/lib/utils'

interface Props {
  archiveUrl: string
}

export function Comments(props: Props) {
  const { archiveUrl } = props

  const { user } = useUser()

  const {
    data: comments,
    hasNextPage,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchComments,
  } = api.comments.get.useInfiniteQuery(
    {
      url: archiveUrl,
    },
    { getNextPageParam: (lastPage) => lastPage.nextCursor },
  )

  const { data: totalComments, refetch: refetchCount } =
    api.comments.count.useQuery({
      url: archiveUrl,
    })

  const { data: userVotes, refetch: refetchUserVotes } =
    api.user.commentVotes.useQuery(
      { archiveUrl },
      {
        enabled: !!user,
      },
    )

  const loading = isLoading || isFetchingNextPage

  return (
    <div className="w-full space-y-8">
      <div className="flex items-center gap-2">
        <h2 className="text-2xl font-bold">Comments</h2>
        <div className="text-muted-foreground flex items-center gap-1">
          <MessageSquareIcon className="h-4 w-4" />
          <span className="text-sm">{formatNumber(totalComments)} </span>
        </div>
      </div>
      <CommentForm archiveUrl={archiveUrl} refetchCount={refetchCount} />
      <div className="space-y-6">
        {comments?.pages
          .flatMap((page) => page.comments)
          .map((comment) => (
            <Comment
              key={comment._id}
              comment={comment}
              userPubkey={user?.pubKey}
              userVotes={userVotes}
              refetchComments={refetchComments}
              refetchCount={refetchCount}
              refetchUserVotes={refetchUserVotes}
            />
          ))}

        {hasNextPage && (
          <InView
            as="div"
            onChange={(inView) => {
              inView && fetchNextPage()
            }}
          />
        )}
        {loading && (
          <div className="mt-2">
            <LoaderCircleIcon className="size-6 animate-spin" />
          </div>
        )}
      </div>
    </div>
  )
}

interface CommentFormProps {
  archiveUrl: string
  parentId?: string
  refetchCount: () => void
}

function CommentForm(props: CommentFormProps) {
  const { archiveUrl, parentId, refetchCount } = props

  const [content, setContent] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { user } = useUser()

  const analytics = useAnalytics()

  const utils = api.useUtils()

  const postCommentMutation = api.comments.post.useMutation({
    onSuccess: async (newComment) => {
      // Update the comments cache but don't refetch.
      // Refetching will cause the comments to be reordered and is a jarring
      // experience for the user. Regardless of the final sort order of the new
      // comment, just show the comment at the top so the user sees it immediately.
      utils.comments.get.setInfiniteData(
        { url: newComment.archiveId, parentId: newComment.parentId },
        (data) => {
          if (!data) return undefined

          if (!data.pages[0].comments.length) {
            data.pages[0].comments = [{ __v: 0, ...newComment }]
          } else {
            data.pages[0].comments = [
              { ...newComment, __v: 0 },
              ...data.pages[0].comments,
            ]
          }

          return data
        },
      )

      analytics.sendEvent('comment_posted', { url: archiveUrl })

      setContent('')
      toast({ title: 'Comment Saved' })

      refetchCount()
    },
    onError: (e) => {
      toast({
        title: 'Error Commenting',
        description: e.message,
        variant: 'destructive',
      })
    },
  })

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()

    try {
      if (!user) throw Error('Must be logged in to comment')

      if (!content.trim()) throw Error('Comment required')

      setIsSubmitting(true)

      await postCommentMutation.mutateAsync({
        content,
        url: archiveUrl,
        parentId,
        author: user.paymail.split('@')[0],
        authorImage: user.image,
      })
    } catch (e) {
      console.error('Failed to submit comment:', e)

      toast({
        title: 'Error Commenting',
        description: e instanceof Error ? e.message : undefined,
        variant: 'destructive',
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  if (!user) return null

  return (
    <form
      onSubmit={handleSubmit}
      className={`space-y-4 ${cn({ 'ml-8': !!parentId })}`}
    >
      <Textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder={parentId ? 'Reply to comment...' : 'Write a comment...'}
        autoFocus={!!parentId}
        rows={2}
      />
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Saving...' : parentId ? 'Reply' : 'Comment'}
      </Button>
    </form>
  )
}

interface CommentProps {
  comment: IComment
  userPubkey: string | undefined
  userVotes: ICommentVote[] | undefined
  refetchComments: () => void
  refetchCount: () => void
  refetchUserVotes: () => void
}

function Comment(props: CommentProps) {
  const {
    comment,
    userPubkey,
    userVotes,
    refetchUserVotes,
    refetchComments,
    refetchCount,
  } = props

  const [isReplying, setIsReplying] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const {
    data: repliesData,
    isLoading,
    refetch: refetchReplies,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = api.comments.get.useInfiniteQuery(
    {
      url: comment.archiveId,
      parentId: comment._id,
    },
    { getNextPageParam: (lastPage) => lastPage.nextCursor },
  )

  const replies = repliesData?.pages.flatMap((page) => page.comments)
  const hasReplies = typeof replies !== 'undefined' && replies.length > 0
  const isOwnComment = comment.user_pubkey === userPubkey

  const userVote = userVotes?.find((vote) =>
    typeof vote.commentId === 'string'
      ? vote.commentId === comment._id
      : vote.commentId._id === comment._id,
  )

  const deleteMutation = api.comments.delete.useMutation({
    onSuccess: async () => {
      toast({ title: 'Comment Deleted' })

      await refetchReplies()
      refetchComments()
      refetchCount()
    },
  })

  const utils = api.useUtils()

  const voteMutation = api.comments.vote.useMutation({
    onSuccess: async (updatedComment) => {
      toast({ title: 'Vote Saved' })

      refetchUserVotes()

      // Update the comment's upvotes in the cache but don't refetch.
      // Refetching will cause the comments to be reordered and is a jarring
      // experience for the user.
      utils.comments.get.setInfiniteData(
        { url: comment.archiveId, parentId: comment.parentId },
        (data) => {
          if (!data) return undefined

          const pageIndex = data.pages.findIndex((page) =>
            page.comments.some(({ _id }) => _id === comment._id),
          )

          data.pages[pageIndex].comments = data.pages[pageIndex].comments.map(
            (existingComment) => {
              if (existingComment._id === comment._id) {
                return { ...existingComment, upvotes: updatedComment.upvotes }
              }
              return existingComment
            },
          )

          return data
        },
      )
    },
  })

  const vote = (vote: 'up' | 'down') => {
    if (!userPubkey) {
      return toast({
        title: 'Must be logged in to vote',
      })
    }

    voteMutation.mutate({
      id: comment._id,
      vote,
    })
  }

  const loading = isLoading || isFetchingNextPage

  return (
    <div className="space-y-4">
      <div className="flex gap-4">
        <Avatar className="h-10 w-10">
          <AvatarImage src={comment.authorImage} alt={comment.author} />
          <AvatarFallback>{comment.author[0].toUpperCase()}</AvatarFallback>
        </Avatar>

        <div className="flex-1 space-y-2">
          <div className="flex items-start justify-between">
            <div className="flex items-center gap-1">
              <p className="text-sm font-medium">{comment.author}</p>
              <div className="text-muted-foreground text-xs">
                <Tooltip>
                  <TooltipTrigger>
                    {formatDistanceToNowStrict(new Date(comment.createdAt), {
                      addSuffix: true,
                    })}
                  </TooltipTrigger>
                  <TooltipContent side="right">
                    <div>
                      {formatDateWithTime(+new Date(comment.createdAt))}
                    </div>
                  </TooltipContent>
                </Tooltip>
              </div>
            </div>
          </div>
          <p className="whitespace-pre-line text-sm">{comment.content}</p>
          <div className="flex items-center gap-5">
            <div className="flex items-center gap-2">
              <button
                onClick={() => vote('up')}
                className={`flex items-center gap-2 text-sm ${cn({
                  'text-theme': userVote?.vote === 'up',
                })}`}
                disabled={isOwnComment}
              >
                <ThumbsUpIcon className="size-4" />{' '}
                {(comment.upvotes ?? 0) > 0 && formatNumber(comment.upvotes)}
              </button>
              <button
                onClick={() => vote('down')}
                disabled={isOwnComment}
                className={cn({
                  'text-theme': userVote?.vote === 'down',
                })}
              >
                <ThumbsDownIcon className="size-4" />
              </button>
            </div>
            {!comment.parentId && (
              <Button
                variant="link"
                size="sm"
                className="flex items-center gap-2 p-0 hover:bg-transparent"
                onClick={() =>
                  !userPubkey
                    ? toast({ title: 'Must be logged in to reply' })
                    : setIsReplying(!isReplying)
                }
              >
                <MessageSquareIcon className="h-4 w-4" />
                {isReplying ? 'Hide Reply' : 'Reply'}
              </Button>
            )}
          </div>
          {hasReplies && (
            <Button
              variant="link"
              size="sm"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              <span className="text-muted-foreground ml-1 text-xs">
                {formatNumber(replies.length)}{' '}
                {replies.length === 1 ? 'reply' : 'replies'}
              </span>
            </Button>
          )}
        </div>
        {isOwnComment && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <MoreVerticalIcon className="h-4 w-4" />
                <span className="sr-only">Open menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-32">
              <DropdownMenuItem
                onClick={() => deleteMutation.mutate({ id: comment._id })}
                className="text-destructive focus:text-destructive"
              >
                <Trash2Icon className="mr-2 h-4 w-4" />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>

      {isReplying && (
        <div className="ml-6">
          <CommentForm
            parentId={comment._id}
            archiveUrl={comment.archiveId}
            refetchCount={refetchCount}
          />
        </div>
      )}

      {hasReplies && (isExpanded || isReplying) && (
        <div className="ml-6 space-y-4 border-l-2 pl-4">
          {replies.map((reply) => (
            <Comment
              key={reply._id}
              comment={reply}
              refetchComments={() => {
                refetchComments()
                refetchReplies()
              }}
              refetchCount={refetchCount}
              refetchUserVotes={refetchUserVotes}
              userPubkey={userPubkey}
              userVotes={userVotes}
            />
          ))}
          {hasNextPage && (
            <InView
              as="div"
              onChange={(inView) => {
                inView && fetchNextPage()
              }}
            />
          )}
          {loading && (
            <div className="mt-2">
              <LoaderCircleIcon className="size-6 animate-spin" />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
