import { PayButton } from '@/components/PayButton'
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { toast } from '@/components/ui/use-toast'
import useBalance from '@/hooks/useBalance'
import {
  CombinedWalletHistory,
  Ticker,
  Wallet,
  bchaddr,
} from '@canonicxyz/wallet-sdk'
import {
  ArrowDownToLineIcon,
  ArrowRightLeftIcon,
  ArrowUpIcon,
  ChevronFirstIcon,
  ChevronLastIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  CopyIcon,
  LogOutIcon,
  QrCodeIcon,
  SettingsIcon,
} from 'lucide-react'
import { ComponentProps, useMemo, useRef, useState } from 'react'

import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import useUser from '@/hooks/useUser'
import { Env } from '@/lib/env'
import { formatCurrency } from '@/lib/formatting'
import { cn, getTxUrl } from '@/lib/utils'
import { useLocalStorage } from '@uidotdev/usehooks'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { LocalStorage } from '@/lib/local-storage'

export function ProfilePage() {
  const { balance, allUtxos, history } = useBalance()
  const { user, fetchUser } = useUser()

  const { addRecentRecipient } = useRecentRecipients()

  const [amount, setAmount] = useState('')
  const [recipientAddress, setRecipientAddress] = useState('')
  const [currency, setCurrency] = useState<'USD' | 'BITCOIN'>('USD')
  const [ticker, setTicker] = useState<Ticker>('BTC')
  const [addressType, setAddressType] = useState<'normal' | 'cashaddress'>(
    'normal',
  )

  const receiveAddress = useMemo(() => Wallet.getChangeAddress(), [])
  const receiveAddressBCH = useMemo(
    () => bchaddr.toCashAddress(receiveAddress),
    [receiveAddress],
  )

  const address =
    addressType === 'cashaddress' ? receiveAddressBCH : receiveAddress

  const onSend: ComponentProps<typeof PayButton>['onSuccess'] = async (
    _args,
  ) => {
    if (recipientAddress.includes('@')) {
      addRecentRecipient(recipientAddress)
    }

    setAmount('')
    setRecipientAddress('')
  }

  const copyAddress = () => {
    navigator.clipboard.writeText(address)
    toast({
      title: 'Address Copied',
    })
  }

  const logout = async () => {
    LocalStorage.deleteAuthToken()
    Wallet.logout()
    await fetchUser()
  }

  if (!user) return null

  const availableTickers =
    allUtxos &&
    Object.values(allUtxos)
      .filter((utxos) =>
        utxos.total_usd > 0
          ? Env.PROD
            ? utxos.ticker !== 'SIGNET'
            : true
          : false,
      )
      .sort((a) => (a.ticker === 'BTC' ? -1 : 1))

  return (
    <div className="mx-auto mt-10 w-full max-w-4xl space-y-6 p-0">
      <Card className="bg-gradient-to-br from-indigo-600 to-indigo-300 dark:from-indigo-600/70 dark:to-indigo-300/70">
        <CardHeader>
          <CardTitle className="text-white">Wallet Balance</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-between gap-2">
            <div className="space-y-1">
              <div className="text-4xl font-bold text-white">{balance}</div>
              <div className="flex items-center divide-x divide-white/50">
                {availableTickers &&
                  [...availableTickers] // Creat a copy so the sort doesn't mutate the original
                    .sort((a, b) => b.total_usd - a.total_usd)
                    .map((utxos, i) => (
                      <div
                        key={utxos.ticker}
                        className={`mt-1 pr-4 text-sm text-white/80 ${cn({ 'pl-4': i !== 0 })}`}
                      >
                        {formatCurrency(utxos.total_usd)} {utxos.ticker}
                      </div>
                    ))}
              </div>
            </div>
            <Dialog>
              <DialogTrigger asChild>
                <button className="self-end">
                  <SettingsIcon className="size-5 stroke-white" />
                </button>
              </DialogTrigger>
              <DialogContent
                className="max-w-screen-md"
                aria-describedby={undefined}
              >
                <DialogHeader>
                  <DialogTitle>Settings</DialogTitle>
                </DialogHeader>
                <div className="space-y-2 text-sm">
                  {Object.entries(user).map(([key, value]) => (
                    <div key={key}>
                      <div className="font-medium capitalize">{key}</div>
                      <div className="text-muted-foreground break-all text-sm">
                        {value}
                      </div>
                    </div>
                  ))}
                  <div className="font-medium capitalize">Seed phrase</div>
                  <div className="text-muted-foreground inline-block break-all text-sm [&:not(:hover)]:blur-sm">
                    {Wallet.getSeed()}
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </CardContent>
      </Card>

      <Tabs defaultValue="send" className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="send">Send</TabsTrigger>
          <TabsTrigger value="receive">Receive</TabsTrigger>
        </TabsList>

        <TabsContent value="send">
          <Card>
            <CardHeader>
              <CardTitle>Send Bitcoin</CardTitle>
              <CardDescription>
                Send BTC to another wallet address
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="grid grid-cols-[1fr,6rem] gap-2">
                <Label htmlFor="amount">
                  Amount ({currency === 'BITCOIN' ? ticker : 'USD'}){' '}
                </Label>
                <Label>Coin</Label>
                <Input
                  id="amount"
                  type="number"
                  placeholder="0.00"
                  step="0.00001"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
                <Select
                  value={ticker}
                  onValueChange={(value) => {
                    setTicker(value as Ticker)
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Currency" />
                  </SelectTrigger>
                  <SelectContent>
                    {availableTickers &&
                      availableTickers.map((utxos) => (
                        <SelectItem key={utxos.ticker} value={utxos.ticker}>
                          {utxos.ticker}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
                <button
                  onClick={() =>
                    setCurrency(currency === 'BITCOIN' ? 'USD' : 'BITCOIN')
                  }
                  className="text-muted-foreground flex items-center gap-1 text-xs"
                >
                  <ArrowRightLeftIcon className="size-4" />
                  Switch to {currency === 'BITCOIN' ? 'USD' : ticker} input
                </button>
              </div>
              <div className="space-y-2">
                <Label htmlFor="address">Recipient Address</Label>
                <RecipientAddressInput
                  address={recipientAddress}
                  setAddress={setRecipientAddress}
                />
              </div>
            </CardContent>
            <CardFooter>
              <div className="">
                <PayButton
                  label="Send"
                  ticker={ticker}
                  onSuccess={onSend}
                  disabled={!amount || !recipientAddress}
                  outputs={[
                    {
                      addressOrPaymail: recipientAddress,
                      amount: amount ? Number(amount) : 0,
                      currency: currency === 'BITCOIN' ? ticker : 'USD',
                    },
                  ]}
                />
              </div>
            </CardFooter>
          </Card>
        </TabsContent>

        <TabsContent value="receive">
          <Card>
            <CardHeader>
              <CardTitle>Receive Bitcoin</CardTitle>
              <CardDescription>
                Share your address to receive bitcoin
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex items-center gap-4">
                <div className="flex-1">
                  <Label>Your Bitcoin Address</Label>
                  <div className="mt-2 flex items-center gap-2">
                    <Input value={address} readOnly />
                    <Button variant="outline" size="icon" onClick={copyAddress}>
                      <CopyIcon className="h-4 w-4" />
                    </Button>
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button variant="outline" size="icon">
                          <QrCodeIcon className="h-4 w-4" />
                        </Button>
                      </DialogTrigger>
                      <DialogContent forceMount>
                        <DialogHeader>
                          <DialogTitle>Scan QR Code</DialogTitle>
                          <DialogDescription>
                            Scan this QR code to get the wallet address
                          </DialogDescription>
                        </DialogHeader>
                        <div className="flex justify-center p-2">
                          <img
                            src={`https://quickchart.io/qr?text=${address}&size=240`}
                            alt="Wallet QR Code"
                            className="h-60 w-60"
                          />
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                  <button
                    onClick={() =>
                      setAddressType(
                        addressType === 'cashaddress'
                          ? 'normal'
                          : 'cashaddress',
                      )
                    }
                    className="text-muted-foreground mt-2 flex items-center gap-1 text-xs"
                  >
                    <ArrowRightLeftIcon className="size-4" />
                    {addressType === 'cashaddress'
                      ? 'Show normal address'
                      : 'Show cash address format (BCH)'}
                  </button>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      <Card>
        <CardHeader>
          <CardTitle>Recent Transactions</CardTitle>
          <CardDescription>Your latest Bitcoin transactions</CardDescription>
        </CardHeader>
        <CardContent>
          {history && <PaginatedTransactions history={history} />}
        </CardContent>
      </Card>
      <div className="flex justify-end">
        <Button onClick={logout} size="sm">
          Log out <LogOutIcon className="size-4" />
        </Button>
      </div>
    </div>
  )
}

const PAGE_SIZE = 10

function PaginatedTransactions({
  history,
}: {
  history: CombinedWalletHistory
}) {
  const [page, setPage] = useState(1)

  const transactions = history.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)

  const hasNextPage = page * PAGE_SIZE < history.length
  const totalPages = Math.ceil(history.length / PAGE_SIZE)
  const hasPreviousPage = page > 1

  return (
    <div className="space-y-4">
      {transactions.map((tx) => (
        <a
          key={tx.txid}
          href={getTxUrl(tx.txid, tx.ticker)}
          rel="noreferrer"
          target="_blank"
          className="block"
        >
          <div
            key={tx.txid}
            className="flex items-center justify-between rounded-lg border p-4"
          >
            <div className="flex items-center gap-4">
              {tx.type === 'received' ? (
                <ArrowDownToLineIcon className="size-5 text-green-500" />
              ) : (
                <ArrowUpIcon className="size-5 text-orange-500" />
              )}
              <div>
                <div className="font-medium">
                  {tx.type === 'received' ? 'Received' : 'Sent'}{' '}
                  {formatCurrency(Number(tx.amount_usd))}
                </div>
                <div className="text-muted-foreground text-sm">{tx.ticker}</div>
              </div>
            </div>
            <div className="text-muted-foreground flex items-center gap-2 text-sm">
              <ClockIcon className="h-4 w-4" />
              {new Date(tx.time * 1000).toLocaleString('en-US', {
                dateStyle: 'short',
                timeStyle: 'short',
              })}
            </div>
          </div>
        </a>
      ))}
      <div className="flex items-center justify-center gap-2">
        <button
          onClick={() => setPage(1)}
          disabled={page === 1}
          className="disabled:opacity-50"
        >
          <ChevronFirstIcon className="size-5" />
        </button>
        <button
          onClick={() => setPage(page - 1)}
          disabled={!hasPreviousPage}
          className="disabled:opacity-50"
        >
          <ChevronLeftIcon className="size-5" />
        </button>
        <div className="text-center text-sm">
          Page {page} of {totalPages}
        </div>
        <button
          onClick={() => setPage(page + 1)}
          disabled={!hasNextPage}
          className="disabled:opacity-50"
        >
          <ChevronRightIcon className="size-5" />
        </button>
        <button
          onClick={() => setPage(totalPages)}
          disabled={page === totalPages}
          className="disabled:opacity-50"
        >
          <ChevronLastIcon className="size-5" />
        </button>
      </div>
    </div>
  )
}

function useRecentRecipients() {
  const [recipients, setRecipients] = useLocalStorage<string[]>(
    'recentRecipients',
    [],
  )

  const addRecentRecipient = (recipient: string) => {
    const existing = [...recipients]

    // Move it to the top of the list
    if (existing.includes(recipient)) {
      existing.splice(existing.indexOf(recipient), 1)
    }

    existing.unshift(recipient)

    setRecipients(existing)
  }

  return { recentRecipients: recipients, addRecentRecipient }
}

function RecipientAddressInput({
  address,
  setAddress,
}: {
  address: string
  setAddress: (address: string) => void
}) {
  const [showRecents, setShowRecents] = useState(false)
  const { recentRecipients } = useRecentRecipients()
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Popover
      open={showRecents && !!recentRecipients.length}
      onOpenChange={setShowRecents}
    >
      <PopoverTrigger asChild>
        <div>
          <Input
            ref={inputRef}
            value={address}
            id="address"
            placeholder="Type an address or paymail"
            onChange={(e) => setAddress(e.target.value)}
            autoComplete="off"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        sideOffset={4}
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="p-0"
      >
        <Command shouldFilter={false}>
          <CommandList>
            <CommandGroup heading="Recents">
              {recentRecipients.slice(0, 10).map((recipient) => (
                <CommandItem
                  key={recipient}
                  value={recipient}
                  onSelect={(v) => {
                    setAddress(v)
                    inputRef.current?.focus()
                  }}
                >
                  {recipient}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
