// Keep this file 'pure Typescript' and not a Node.js module, since
// we reference this file in the frontend.
import { ROUTES } from './constants.js'

type Metatags = {
  title: string
  description?: string
}

const metatags: Record<string, Metatags> = {
  [ROUTES.ABOUT]: {
    title: 'Why Ark?',
    description: 'Learn the why behind Ark and what inspired us to build it.',
  },
  [ROUTES.DIGGING_DEEPER]: {
    title: 'Digging Deeper | Ark',
    description: 'Learn about how Ark works and the technology behind it.',
  },
  [ROUTES.PRIVACY_POLICY]: {
    title: 'Privacy Policy | Ark',
  },
  [ROUTES.FEED]: {
    title: 'Feed | Ark',
    description: 'Explore the latest archives.',
  },
  [ROUTES.BROWSE]: {
    title: 'Feed | Ark',
    description: 'Explore the latest archives.',
  },
  [ROUTES.STATS]: {
    title: 'Stats | Ark',
    description: 'See the latest stats for Ark.',
  },
  [ROUTES.LOGIN]: {
    title: 'Login to Ark',
    description: 'Login to Ark to get started.',
  },
  [ROUTES.SIGNUP]: {
    title: 'Sign up for Ark',
    description: 'Sign up for Ark to get started.',
  },
  [ROUTES.BOOKMARKS]: {
    title: 'Bookmarks | Ark',
    description: 'View your bookmarks.',
  },
  [ROUTES.PROFILE]: {
    title: 'Profile | Ark',
    description: 'View your profile.',
  },
  [ROUTES.NEW_ARCHIVE]: {
    title: 'Create a New Archive on Ark',
    description:
      'Create a new archive on Ark to preserve your content on Bitcoin forever.',
  },
  [ROUTES.DOWNLOADS]: {
    title: 'Downloads | Ark',
    description:
      'Download the iOS app or Chrome extension to make archiving sites even easier.',
  },
  [ROUTES.ADMIN]: {
    title: 'Admin | Ark',
    description: 'Manage archives.',
  },
}

export function getMetatags(path: string): Metatags | null {
  if (path.includes('/admin')) return metatags[ROUTES.ADMIN]

  if (path in metatags) return metatags[path]

  return null
}
